
html, body {
  /*height: 100%; !* Ensure html and body cover the entire viewport height *!*/
  /*margin: 0; !* Remove default margin *!*/
  /*padding: 0; !* Remove default padding *!*/
  zoom: 70%; /* Note: This zoom level might affect the layout */
  /*background-color: white; !* Set background color to transparent *!*/
  /*scroll-behavior: smooth;*/
  /*min-height:500vh;*/

}

.background, body {

  background-image: url('./Assets/bg2.png'); /* Set your SVG background here */
  background-size: cover;
  /*background-position: center center;*/
  background-repeat: no-repeat;
  background-attachment: fixed; /* Optional, for a fixed background */

  display: flex;
  justify-content: center;
  align-items: center;


  /*min-height:500vh;*/

}
body::-webkit-scrollbar{
  display: none;
}

