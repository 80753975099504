
.App {
    margin-top: 400px; /* Adjust based on your navbar's actual height */

}


.products {
        position: absolute;
        top: 280px; /* Adjust this value to position the element from the top */
        left: 100px; /* Center the element horizontally */
        justify-content: center; /* Center content horizontally inside the element */
        font-family: Futura;
        font-size: 30px;
        color: white;
        /*margin:20px;*/
        margin-bottom: 100px;
    }

    .search-bar {
        position: absolute;
        top: 280px; /* Adjust this value to move the bar down from the top */
        right:-80px; /* Center the bar horizontally */
        transform: translateX(-50%); /* Adjust for the element's own width */
        display: flex;
        justify-content: center; /* Center content inside the element */
        align-items: center; /* Center items vertically */
        padding: 10px;
        z-index :1;
        border-radius: 30px;
        /* Additional styles as needed */
    }
    .container-fluid.sm {

        padding-right: 100px;
        padding-left: 100px;
        display: flex;
        /*align-items: center;*/
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .card {
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.35);
        border-radius: 10px;
        flex: 0 1 calc(25% - 20px); /* Control width using flex-basis */
        margin: 10px;
        text-align: center;
        font-family: Futura;
        box-sizing: border-box; /* Include padding and border in the element's size */
        min-width: 250px;
        /*background: rgba(255, 255, 255, 0.19);*/
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(90px);

    }

    .search-input {
        padding: 10px;
        text-align: center;
        font-family: Futura;
        font-size: 20px;/* Add some padding inside the input field */

        border: 1px solid #ccc;
        border-radius: 20px;/* Optional: add a border */
        margin-right: 10px; /* Add some space between the input field and the button */
        /* Additional styling as needed */
    }

    .row{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .search-icon {
        padding: 8px 10px; /* Match the padding of the input field */
        border-radius: 30px; /* Optional: round the corners */
        border: 1px solid #ccc; /* Optional: add a border */
        background-color: white; /* Or any other color */
        cursor: pointer; /* Change cursor on hover */
        /* Additional styling as needed */
    }

    .search-icon .fa-camera {
        color: #333; /* Change icon color */
        /* Additional styling as needed */
    }


.card-header{

    padding: 10px;
    background-color: Black;
    font-size: 20px;
    color: white;
}
.card-body{
    font-size: 5px;
    padding: 10px;
    background-color: white;
    justify-content: space-evenly;

}
.text-center{
    text-align: center;
}
.AddToCartButton{

    padding: 20px;
    background-color: rgba(0, 0, 0, 0.1);

    font-size: 20px;
    border: none;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;



}
.AddToCartButton:hover{
    background-color: rgba(0, 0, 0, 0.3);
}
