.navbar{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 200px;
    padding-bottom: 200px;
    background-color: Transparent;
    z-index: 100;
    transition: background-color 0.9s ease, top 0.9s ease;
}
.main-content {
    padding-top: 200px; /* Adjust as necessary */
    /* Other styles... */
}

.navbar.scrolled {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.75);
    /*background-color: #000000;*/
    /*background-color: #b80404;*/
    /*background-color: #7a0b08;*/


    /*backdrop-filter: blur(90px);*/


}
.navbar-placeholder {
    height: 200px; /* Same as navbar */
    display: block; /* Ensure it takes up space */
}

.navLogo {
    height: 100%;
    width: 100%;
    margin-top:200px;

}
.navbar-content {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100%; /* Take full height of the navbar */
    width: 100%; /* Take full width of the navbar */
}

.navbar-user-info {
    display: flex;
    align-items: center;
    /* Additional styling for spacing or aesthetics can go here */
}

.navbar-user-info button {
    position:absolute;
    right: 10px;
    top: 10px;

    padding: 8px 12px; /* Adjust padding to fit your design */
    margin-left: 10px; /* Creates some space between text and button if needed */
    cursor: pointer; /* Makes it clear the button is clickable */
    /* Additional button styling here */
}
.logoutIcon {
    position: absolute;
    left: 118px;
    top: 80px;
font-size: 60px; /* Adjust icon size */


    color: white; /* Change the icon color */
    transition: color 0.3s ease; /* Smooth transition for color change */
}
.cartIcon{
    position: absolute;
    right: 118px;
    top: 80px;
    font-size: 60px; /* Adjust icon size */


    color: white; /* Change the icon color */
    transition: color 0.3s ease; /* Smooth transition for color change */
}
.cart-item-count{
    position: absolute;
    font-family: Futura;
    right: 80px;
    top: 60px;
    font-size: 30px; /* Adjust icon size */
    color: white
}

.listItemText .cartItem {
    font-size: 64px !important;
}

.Welcome{
    font-size: 32px;
    color: white;
    font-family: Futura;

    margin-bottom: 200px;
    text-align: center;
    text-decoration:none;

}
